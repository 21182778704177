'use client'
import { getUser } from '@jobmojito/app/utils/auth/getUser'
import { Button } from '@nextui-org/react'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { createTemporaryUser } from './createTemporaryUser'
import { uploadFileAndStartCheck } from '@jobmojito/app/utils/uploadFileAndStartCheck'
import { useError } from '@jobmojito/components/providers/ErrorProvider'
import { callUserPostLogin } from './callUserPostLogin'
import getClientSupabase from '@jobmojito/app/utils/getClientSupabase'

export default function ResumeUploadLandingButton({ geo }: { geo?:string }) {
  const [isBounce, setIsBounce] = useState(false)

  useEffect(() => {
    // Set interval for bouncing effect
    const bounceInterval = setInterval(() => {
      // Set isBounce to true
      setIsBounce(true)

      // After 100 millisecond, set isBounce back to false
      setTimeout(() => {
        setIsBounce(false)
      }, 100)
    }, 4000) // Interval of 4 seconds (3 seconds + 1 second)

    // Clear interval on component unmount
    return () => clearInterval(bounceInterval)
  }, [])

  const { showError, clearError } = useError()
  const [loading, setLoading] = useState<boolean>(false)
  const supabase = getClientSupabase();

  // handle the custom icon for the file loading form
  const fileInputRef = useRef<HTMLInputElement | null>(null) // Create a ref for the file input
  const startFileUpload = () => {
    // Trigger the file input click event
    // Then it will be handled by the uploadFileAndStartCheck function
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    // check if the user exists
    let user = null
    try {
      user = await getUser(supabase)
    } catch (error) {
      // no user
    }

    if (user) {
      // if exists, load the resume to supabase,
      // create a new record in the resume-check table,
      // and redirect to the resume page
      uploadFileAndStartCheck({
        event,
        userId: user.id,
        profileId: user.app_metadata?.profile_id,
        merchantId: user.app_metadata?.merchant_id,
        setLoading,
        showError,
        clearError,
        supabase
      })
    } else {
      // if not exists -- create a new user and login,
      // then do login and start the check
      const { email, password } = await createTemporaryUser()

      if (!email || !password) {
        return
      }

      // set loading sooner
      setLoading(true);

      // login with the newly created user with temporary credentials
      const d = (await supabase.auth.signInWithPassword({ email, password }));
      const postLoginData = await callUserPostLogin(geo)
      // fetch the newly created user
      supabase.auth.refreshSession();
      user = await getUser(supabase)

      // load the resume and start the check
      if (user?.id) {
        uploadFileAndStartCheck({
          event,
          userId: user.id,
          profileId: user.app_metadata?.profile_id,
          merchantId: user.app_metadata?.merchant_id,
          setLoading,
          showError,
          clearError,
          supabase
        })
      }
    }
  }

  return (
    <>
      {/* The uploaded file is stored in this hidden input */}
      {/* After changing the file, the uploadFileAndStartCheck function will be called */}
      <input
        className={'hidden'}
        ref={fileInputRef}
        type={'file'}
        accept={'.pdf,.doc,.docx,.rtf,.pages,.odt'}
        // When a file is selected, the uploadFileAndStartCheck function will be called
        onChange={handleFileChange}
      />
      <Button
        isDisabled={loading}
        isLoading={loading}
        onClick={startFileUpload}
        className={`rounded-lg text-white font-medium text-md p-5 h-[50px] ${
          isBounce ? 'scale-110' : 'scale-100'
        }`}
        style={{
          background:
            'radial-gradient(ellipse farthest-corner at 10% 10%, rgba(196, 23, 115, 1) 0%, rgba(200, 0, 255, 1) 100%)',
        }}
      >
        Click to upload your resume...
      </Button>
    </>
  )
}
