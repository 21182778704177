import { ChangeEvent } from 'react'
import { SupabaseClient } from '@supabase/supabase-js'

// handle the uploaded file here
// upload the file to the supabase storage and call the resume check function
export const uploadFileAndStartCheck = async ({
  event,
  userId,
  profileId,
  merchantId,
  setLoading,
  showError,
  clearError,
  supabase,
}: {
  event: ChangeEvent<HTMLInputElement>
  userId: string
  profileId?: string
  merchantId?: string
  setLoading: (loading: boolean) => void
  showError: (message: string) => void
  clearError: () => void
  supabase: SupabaseClient
}) => {

  // get the file from the file input
  const file = event.target?.files?.[0]
  const oldFileName = file?.name;

  let newFileName = crypto.randomUUID().slice(0,13);
  if (oldFileName && oldFileName?.split('.').length>1) {
    newFileName = newFileName + '.'+oldFileName.split('.')[oldFileName.split('.').length-1];
  }

  // clear a previous error
  clearError()

  // if no file is selected, show an error
  if (!file) {
    showError('Please select a file to upload')
    return
  }

  // set the loading state to true
  setLoading(true)

  // upload file to supabase storage and get the uploaded file path
  try {
    const id= (profileId!==undefined?profileId:userId);

    const { data: fileData, error: fileError } = await supabase.storage
      .from('private-resume')
      .upload(`${id}/${newFileName}`, file, { upsert: true });

    if (!fileData) {
      // ERROR uploading handling
      console.error(fileError, 'fileError')
      showError(fileError.message)
      setLoading(false)
      return
    }
    
    // SUCCESS uploading handling
    // insert a new row to the resume_check table after uploading the file
    const uuid = self.crypto.randomUUID();
    const { data: resumeData, error: resumeError } = await supabase
      .from('resume_check')
      .insert({
        id: uuid,
        resume_file_local_path: fileData.path,
        resume_file_local_name: file?.name,
        profile_id: profileId||'',
        merchant_id: merchantId||''
      });

    if (resumeError) {
      // ERROR handling inserting the resume_check row
      console.error(resumeError, 'resumeDataMissing')
      showError(resumeError.message)
      setLoading(false)
      return
    }

    // SUCCESS handling
    // get the inserted resume_check id
    const resumeCheckId = uuid;
    // redirect to the resume page
    window.location.assign(`/resume/result/${resumeCheckId}`)
  } catch (err: any) {
    // ERROR handling
    console.error(err)
    showError(err?.message)
  }
}
